import { h, createRef, Fragment } from "preact";
import { useState, useEffect } from "preact/hooks";
import RacerHero from "./RacerHero";
import styled from "styled-components";
import { u, color } from "../util/style";

const sortTokensByNo = (a, b) => b.tokenNo - a.tokenNo;

const FilterBar = styled.div`
  background: ${color("black")};
  display: flex;
  align-items: center;
  margin: 0 0 ${u(2)} 0;
  padding: 0 ${u()};
  border-radius: ${u(0.3)};
  height: 42px;

  &:before {
    content: "Filter By: ";
    color: ${color("accent")};
    height: 100%;
    padding: 0 ${u()} 0 0;
    border-right: 2px solid ${color("secondary")};
    display: flex;
    align-items: center;
  }
`;

const Label = styled.label`
  white-space: nowrap;
  color: ${color("primary")};
`;

const FilterWrapper = styled.div`
  padding: ${u(0.5)} ${u(2)};
  height: 100%;
  border-right: 2px solid ${color("secondary")};
  display: flex;
  align-items: center;

  & > ${Label} {
    margin: ${(props) =>
      props.alignLabel === "left" ? `0 12px  0 0` : `0 0 0 12px`};
  }
`;

const Select = styled.select`
  border: 1px solid ${color("accent")};
  cursor: pointer;
  border-radius: ${u(0.2)};
  padding: ${u(0.2)} ${u()};
  background: none;
  color: ${color("accent")};
  margin: 0;
  max-width: ${u(8)};
`;

const H4Accent = styled.h4`
  color: ${color("accent")};
  margin-left: ${u()};
`;

const Checkbox = styled.input`
  border: 1px solid ${color("accent")};
  cursor: pointer;
`;

const Filter = ({ label, alignLabel = "left", id, type, children }) => (
  <FilterWrapper alignLabel={alignLabel}>
    {label && alignLabel === "left" && <Label for={id}>{label}</Label>}
    {children}
    {label && alignLabel === "right" && <Label for={id}>{label}</Label>}
  </FilterWrapper>
);

const filterTokens = (tokens, filters) => {
  console.log(filters);
  return Object.values(filters)
    .reduce((filteredTokens, filter) => {
      return filteredTokens.filter((token) =>
        filter
          ? filter.exists
            ? token[filter.key]
            : filter.value
            ? token[filter.key] == filter.value
            : token
          : token
      );
    }, tokens)
    .sort(sortTokensByNo);
};

export default () => {
  const [tokens, setTokens] = useState([]);
  const [models, setModels] = useState([]);
  const [foundries, setFoundries] = useState([]);
  const [filteredTokens, setFiltersedTokens] = useState([]);
  const [filters, setFilters] = useState({});

  useEffect(() => {
    (async () => {
      const response = await fetch("/api/tokens").then((res) => res.json());
      console.log(
        Object.values(response)
          .map((t) => t.name)
          .sort()
      );
      setTokens(Object.values(response));
      setFiltersedTokens(filterTokens(Object.values(response), filters));
    })();
  }, []);

  useEffect(() => {
    setModels(
      tokens.reduce(
        (a, token) => (a.includes(token.model) ? a : a.push(token.model) && a),
        []
      )
    );
    setFoundries(
      tokens.reduce(
        (a, token) =>
          a.includes(token.foundry) ? a : a.push(token.foundry) && a,
        []
      )
    );
  }, [tokens]);

  useEffect(() => {
    setFiltersedTokens(() => filterTokens(tokens, filters));
  }, [filters]);
  return (
    <>
      <FilterBar>
        <Filter label="Gen:" id="filter-generation">
          <Select
            onChange={(e) =>
              setFilters({
                ...filters,
                generation: { key: "generation", value: e.target.value },
              })
            }
          >
            <option>All</option>
            <option value={1}>Gen 1</option>
            <option value={2}>Gen 2</option>
            <option value={3}>Gen 3</option>
          </Select>
        </Filter>
        <Filter label="Model:" id="filter-model">
          <Select
            onChange={(e) =>
              setFilters({
                ...filters,
                model: { key: "model", value: e.target.value },
              })
            }
          >
            <option>All</option>
            {models.map((model) => (
              <option value={model}>{model}</option>
            ))}
          </Select>
        </Filter>
        <Filter label="Foundry:" id="filter-model">
          <Select
            onChange={(e) =>
              setFilters({
                ...filters,
                foundry: { key: "foundry", value: e.target.value },
              })
            }
          >
            <option>All</option>
            {foundries.map((foundry) => (
              <option value={foundry}>{foundry}</option>
            ))}
          </Select>
        </Filter>
        <Filter label="For Sale" alignLabel="right" id="filter-sale">
          <Checkbox
            type="checkbox"
            id="filter-sale"
            onChange={(e) =>
              setFilters({
                ...filters,
                onSale: { key: "onSale", exists: e.target.checked },
              })
            }
          />
        </Filter>
        <Filter label="Special Edition" alignLabel="right" id="filter-special">
          <Checkbox
            type="checkbox"
            id="filter-special"
            onChange={(e) =>
              setFilters({
                ...filters,
                specialEdition: {
                  key: "specialEdition",
                  exists: e.target.checked,
                },
              })
            }
          />
        </Filter>
      </FilterBar>
      <div className="result">
        <H4Accent>
          <strong>{filteredTokens.length}</strong> Assets
        </H4Accent>
      </div>
      <div className="racers">
        {filteredTokens.map((token) => (
          <RacerHero token={token} />
        ))}
      </div>
    </>
  );
};
// <p>Sort by:</p>
// <Select>
//   <option>Latest</option>
//   <option>Name</option>
//   <option>Owner</option>
//   <option>Price</option>
//   <option>Date created</option>
// </Select>
