import { h, Fragment } from "preact";
import { getHashFromIpfsUrl } from "../util";
import styled from "styled-components";

const RacerHero = styled.div`
  // height: auto;
  // margin: 0 $u 0 0;
  // margin: 0;
  // position: relative;
  // width: $u * 16;
`;

export default ({ token, showDetails = true }) => {
  const imageHash = getHashFromIpfsUrl(token.image);
  const assetHash = getHashFromIpfsUrl(token.asset_url);
  let username = token?.openseaUser?.user?.username;
  return (
    <RacerHero className="racer-hero">
      <div className="hero small">
        <a href={`/view/${assetHash}`}>
          <img src={`/${imageHash}_thumb.png`} />
        </a>
        <a href={`/view/${assetHash}`} className="pattern view3d-link">
          View in 3D
        </a>
        {token.onSale ? (
          <a className="buyNow" href={token.onSale.openseaUrl}>
            <span>Buy Now</span>
            <br />
            <span>{token.onSale.priceInEther} ETH</span>
          </a>
        ) : null}
        <div className="avatar">
          {username ? (
            <p>
              <a href={`https://opensea.io/${username}`}>Owned by {username}</a>
            </p>
          ) : (
            <p>Owner unknown</p>
          )}
        </div>
      </div>
      {showDetails ? (
        <>
          <div className="desc-top">
            <p className="name">
              <span className="highlight">#{token.tokenNo}</span>
              <a href={`/view/${assetHash}`}>{token.name}</a>
            </p>
            <p className="opensea-link">
              <a
                href={`https://opensea.io/assets/0x73d203f6138030f17706a99d760e05d01cab83e8/${token.tokenNo}`}
              >
                View on OpenSea
              </a>
            </p>
          </div>
          <div className="desc-bottom">
            <p className="detail">
              Generation:
              <span>{token.generation}</span>
            </p>
            <p className="detail">
              Model: <span>{token.model}</span>
            </p>
            <p className="detail">
              Foundry:
              <span>{token.foundry}</span>
            </p>
            <p className="detail">
              Collectable type:
              <span className="cap">{token.type}</span>
            </p>
            <p className="owner">
              Owner: <span>{token.owner}</span>
            </p>
          </div>
        </>
      ) : null}
    </RacerHero>
  );
};
