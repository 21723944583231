import { h, Fragment } from "preact";
import { useState, useEffect } from "preact/hooks";
import RacerHero from "./RacerHero";
import styled from "styled-components";
import { u, color } from "../util/style";

const P = styled.p`
  color: ${color("accent")};
`;

const Marquee = styled.marquee`
  color: ${color("accent")};
`;

const Heading = styled.h3`
  background: ${color("black")};
  color: ${color("accent")};
  padding: ${u()};
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
`;

const Wrapper = styled.div`
  border: 1px solid var(--tertiary);
  border-radius: ${u(0.25)};
  padding: ${u(6)} ${u(2)} 0 ${u(2)};
  position: relative;
  overflow: hidden;
`;

const Racers = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(192px, 1fr));
  grid-template-rows: auto;
  column-gap: ${u(1.5)};
  row-gap: ${u(1.5)};
  padding: 0;
  margin-bottom: ${u(2)};

  & .hero {
    margin: 0;
  }
`;

export default () => {
  const [tokens, setTokens] = useState([]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    (async () => {
      const tokens = await fetch("/assets/on-sale").then((res) => res.json());
      setTokens(tokens);
      setLoading(false);
    })();
  }, []);
  return (
    <Wrapper>
      <Heading>Racers currently for sale ({tokens.length})</Heading>
      <Racers>
        {tokens
          .sort((a, b) => +a.onSale.priceInEther - +b.onSale.priceInEther)
          .map((token) => (
            <RacerHero
              className="racer-hero"
              token={token}
              showDetails={false}
            />
          ))}
        {!tokens.length && !loading && (
          <P>Sorry, no racers currently for sale, check back soon!</P>
        )}
        {loading && <Marquee>Loading racers...</Marquee>}
      </Racers>
    </Wrapper>
  );
};
