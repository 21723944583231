import detectEthereumProvider from "@metamask/detect-provider";
import account, { getUserFromCookies } from "./account";
import { render, h, Fragment, createRef } from "preact";
import { useState, useEffect } from "preact/hooks";
import { getHashFromIpfsUrl } from "./util";
import { AllTokens, RacersForSale } from "./components";
import { ThemeProvider } from "styled-components";

const accountEl = document.querySelector(".account");

let currentAccount;

const loginButton = document.createElement("button");

loginButton.innerText = "Login";
loginButton.addEventListener("click", () => {
  login();
});

const logoutButton = document.createElement("button");
logoutButton.innerText = "Logout";
logoutButton.addEventListener("click", () => {
  logout();
});

const setLoggedIn = (user) => {
  accountEl.innerHTML = `<span>Connected to</span> <span class="account-hash">${user.address}</span> <nav><a href="/account/${user.address}">View Account</a></nav>`;
  accountEl.appendChild(logoutButton);
};

const setLoggedOut = () => {
  accountEl.innerHTML = "";
  accountEl.appendChild(loginButton);
};

const user = getUserFromCookies();

if (user) {
  fetch("/auth/status").then((res) => {
    const { status } = res;
    if (status === 401) {
      setLoggedOut();
    } else if (status === 200) {
      setLoggedIn(user);
    }
  });
} else {
  setLoggedOut();
}

const logout = async () => {
  const response = await fetch("/auth/logout");
  const user = getUserFromCookies();
  if (!user || !user.loggedIn) {
    setLoggedOut();
    window.location.reload();
  }
};

const connect = () => {
  ethereum
    .request({ method: "eth_requestAccounts" })
    .then((accounts) => {
      if (accounts.length) {
        login(accounts[0]);
      }
    })
    .catch((err) => {
      if (err.code === 4001) {
        //         // EIP-1193 userRejectedRequest error
        //         // If this happens, the user rejected the connection request.
        console.log("Please connect to MetaMask.");
      } else {
        console.error(err);
      }
    });
};

// this returns the provider, or null if it wasn't detected
const login = async () => {
  const provider = await detectEthereumProvider();
  if (provider) {
    // login(accounts[0]);
    await account.getToken();
    const user = getUserFromCookies();
    if (user && user.loggedIn) {
      setLoggedIn(user);
      window.location.reload();
    }
  } else {
    console.log("Please install MetaMask!");
    accountEl.innerText = "Please install MetaMask to login";
  }
};

const allNftContainer = document.querySelector(".all-nfts");

const theme = {
  spacing: {
    u: 12,
  },
  colors: {
    accent: getComputedStyle(document.documentElement)
      .getPropertyValue("--tertiary")
      .trim(),
    primary: getComputedStyle(document.documentElement)
      .getPropertyValue("--primary")
      .trim(),
    secondary: getComputedStyle(document.documentElement)
      .getPropertyValue("--secondary")
      .trim(),
    black: "#111",
  },
};

if (allNftContainer) {
  render(
    <ThemeProvider theme={theme}>
      <AllTokens />
    </ThemeProvider>,
    allNftContainer,
    allNftContainer
  );
}

const racersForSaleContainer = document.querySelector(".racers-for-sale");

if (racersForSaleContainer) {
  render(
    <ThemeProvider theme={theme}>
      <RacersForSale />
    </ThemeProvider>,
    racersForSaleContainer
  );
}
