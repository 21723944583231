import cookie from "cookie";
import { getSignInMessage } from "../../shared/util";
import Eth from "web3-eth";

const nonceEndpoint = "/auth/nonce";
const tokenEndpoint = "/auth/token";

const web3 = new Eth(Eth.givenProvider);

export const getUserFromCookies = () => {
  const cookies = cookie.parse(document.cookie);
  let user;
  if (cookies.user) {
    user = JSON.parse(cookies.user);
  }
  return user;
};

const account = {
  async getToken() {
    const accounts = await ethereum.request({ method: "eth_requestAccounts" });

    const nonce = await fetch(
      `${nonceEndpoint}/${accounts[0]}`,
      {}
    ).then((res) => res.text());

    const address = accounts[0].toLowerCase();

    let signature;

    try {
      signature = await web3.personal.sign(getSignInMessage(nonce), address);
    } catch (err) {
      // handle cancel
    }

    if (signature) {
      await fetch(tokenEndpoint, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          address,
          signature,
        }),
      });
    }
  },
};

export default account;
